<template>
  <div>
    <div class="train-answer-header">
      <div class="train-answer-title">
        <div>{{commonTiInfo.name}}</div>
        <div class="train-answer-time">{{commonTiInfo.duration}}</div>
      </div>
      <el-button type="warning" @click="handleCommit">交卷</el-button>
    </div>
    <div class="page-content" v-loading="loading">
      <div class="train-answer-content">
        <!-- 选择题 -->
        <answerchoice
          v-if="commonTiInfo.type === tiDesc.choice" 
          :topic="choiceTopic" 
          :currentIndex="commonTiInfo.currentIndex">
        </answerchoice>
        <!-- 创作题 -->
        <answerCreate 
          v-if="commonTiInfo.type === tiDesc.create"
          :topic="createTopic" 
          :currentIndex="commonTiInfo.currentIndex">
        </answerCreate>
      </div>
      <answerCard 
        :cardList="cardList"
        :currentIndex="commonTiInfo.currentIndex"
        @select="cardSelect"
        @pre="preQuestion"
        @next="nextQuestion"
        @answer="toScratch"></answerCard>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { 
  getSubjectSortList, 
  getScratchCDetail, 
  getProgramDetail, 
  doUpdateTime,
  createScratchC,
  doFinish
} from '@/api'
import answerCard from '@/components/answer/answer-card.vue'
import answerchoice from '@/components/answer/answer-choice.vue'
import answerCreate from '@/components/answer/answer-create.vue'
import { reactive, ref, onMounted, onUnmounted } from 'vue'
import { IanswerCard } from "@/types/answer"
import { ElMessage, ElMessageBox } from 'element-plus'
import { tiDesc } from "@/utils/answer"
// import pick from 'lodash/pick'
import { cloneDeep, isEqual } from 'lodash'
import { getScratchHost } from '@/libs/tools'
import { getToken } from '@/utils/auth'

onMounted(() => {
  tabWatch()
})
onUnmounted(() => {
  removeTabwatch()
  if (timer.value) {
    clearInterval(timer.value)
  }
})

const route = useRoute()
const router = useRouter()
const { id } = route.params
const { recordId } = route.query
const cardList = ref<Array<IanswerCard>>([])
const commonTiInfo = reactive({
  currentIndex: 0,
  type: 0,
  duration: '00:00:00',
  name: ''
})
const timer = ref<any>(null)

const choiceTopic = ref<any>({})
const createTopic = ref<any>({})

const loading = ref(false)

function format(sec:number) {
  const hours = parseInt(String(sec / (60 * 60)), 10);
  const minutes = parseInt(String((sec - hours * 60 * 60) / 60), 10);
  const seconds = sec - hours * 60 * 60 - minutes * 60;

  function completion(num:number) {
    return num > 9 ? num : String(0) + num;
  }

  return `${completion(hours)}:${completion(minutes)}:${completion(seconds)}`;
}
const secTimer = ref<any>(null)
function formatSeconds(sec:number) {
  let second = sec;
  commonTiInfo.duration = format(second);
  if (secTimer.value) {
    clearInterval(secTimer.value);
  }
  secTimer.value = setInterval(() => {
    second++;
    commonTiInfo.duration = format(second);
  }, 1000);
}

const getCardList = () => {
  getSubjectSortList({recordId}).then((res:any) => {
    if(res.code !== '200') return
    cardList.value = res.data.dataList || []
    commonTiInfo.name = res.data.name
    commonTiInfo.currentIndex = 0
    formatSeconds(res.data.duration)
    getTiDetail(cardList.value[commonTiInfo.currentIndex])
  })
} 

const upCardList = () => {
  loading.value = true
  getSubjectSortList({recordId}).then((res:any) => {
    loading.value = false
    if(res.code !== '200') return
    cardList.value = res.data.dataList || []
  })
}

function copyAnswer (data:any) {
  data.copyAnswer = cloneDeep(data.answer)
}

function getChoice(showId: string) {
  loading.value = true
  getScratchCDetail({
    showId,
    recordId
  }).then((res:any) => {
    loading.value = false
    if(res.code !== '200') return
    if(res.data.type===1) {
      res.data.answer = res.data.answer.split('')
    }
    copyAnswer(res.data)
    choiceTopic.value = res.data
  })
}

function getCreate(showId: string) {
  loading.value = true
  getProgramDetail({
    showId,
    recordId
  }).then((res:any) => {
    loading.value = false
    if(res.code !== '200') return
    createTopic.value = res.data
  })
}

function getTiDetail(item:IanswerCard) {
  commonTiInfo.type = item.type
  if(item.type === tiDesc.choice) {
    getChoice(item.showId)
  } else if(item.type === tiDesc.create) {
    getCreate(item.showId)
  } else {
    return
  }
}

function checkSave() {
  if(cardList.value[commonTiInfo.currentIndex].type === tiDesc.choice) { // 选择题才去校验保存答题
    if(isEqual(choiceTopic.value.copyAnswer, choiceTopic.value.answer)) return
    console.log(choiceTopic.value)
    // 保存答题
    createScratchC({
      showId: choiceTopic.value.showId, 
      recordId,  
      answer: choiceTopic.value.type===1?choiceTopic.value.answer.join(''):choiceTopic.value.answer,
    }).then((res:any) => {
      if(res.code !== '200') return
      copyAnswer(choiceTopic.value)
      upCardList()
    })
  }
}

function setCardAndIndex(index:number) {
  commonTiInfo.currentIndex = index
  getTiDetail(cardList.value[commonTiInfo.currentIndex])
}

function nextQuestion() {
  checkSave()
  setCardAndIndex(commonTiInfo.currentIndex + 1)
}
function preQuestion() {
  checkSave()
  setCardAndIndex(commonTiInfo.currentIndex - 1)
}
function cardSelect(index:number) { 
  checkSave()
  setCardAndIndex(index)
}

function handleUpdateTime () {
  function updateTime () {
    doUpdateTime({
      seconds: 10,
      recordId
    }).then((res:any) => {
      if (res.error) return
    })
  }
  timer.value = setInterval(updateTime, 10000)
}

function findUnanswerTopic(data:any) {
  return data.filter((item:any)=>item.state===2);
}


function handleCommit() {
  const unTopics = findUnanswerTopic(cardList.value);
  let temp =
    '<div>以下题目未答：</div><ul style="margin: 15px 0 15px 15px; color: #F56C6C;">';
  if (unTopics.length) {
    unTopics.forEach((category:IanswerCard) => {
      temp += `<li><span>${
        category.showId
      } </span><content> ${category.subjectName || ''}</content></li>`;
    });
    temp += "</ul><div>请确认答题是否结束？</div>";
  } else {
    temp = "<p>交卷后无法更改，请确认答题是否结束？</p>";
  }
  ElMessageBox.confirm(
    temp,
    {
      confirmButtonText: '确 认',
      cancelButtonText: '取 消',
      dangerouslyUseHTMLString: true,
    }
  ).then(() => {
    doFinish({
      recordId
    }).then((res:any) => {
      if(res.code !== '200') return
      ElMessage.success('交卷成功')
      router.push('/train')
    })
  }).catch(() => {
    
  })
}

let hiddenProperty:any = null;
const onVisibilityChange = () => {
  if (document[hiddenProperty]) {
    console.log("离开......");
  } else {
    console.log("进入......");
    upCardList()
  }
}
function tabWatch () {
  // 不同浏览器 hidden 名称
  if ('hidden' in document) {
    hiddenProperty = 'hidden'
  } else if ('webkitHidden' in document) {
    hiddenProperty = 'webkitHidden'
  } else if ('mozHidden' in document) {
    hiddenProperty = 'mozHidden'
  }
  const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange')
 
  document.addEventListener(visibilityChangeEvent, onVisibilityChange)
}

function removeTabwatch () {
  let hiddenProperty:any = null
  if ('hidden' in document) {
    hiddenProperty = 'hidden'
  } else if ('webkitHidden' in document) {
    hiddenProperty = 'webkitHidden'
  } else if ('mozHidden' in document) {
    hiddenProperty = 'mozHidden'
  }
  const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange')
  if (onVisibilityChange) {
    document.removeEventListener(visibilityChangeEvent, onVisibilityChange);
  }
}

const toScratch = () => {
  window.open(`${getScratchHost()}?editType=answer&token=${getToken().token}&courseId=0&subjectId=${createTopic.value.subjectId}&sourceType=1&showId=${createTopic.value.showId}&recordId=${recordId}&tiRecord=${createTopic.value.recordId}`)
}

getCardList()
handleUpdateTime()

</script>

<style lang="scss" scoped>
.train-answer-header {
  height: 72px;
  background: #000000;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .train-answer-title {
    display: flex;
  }
  .train-answer-time {
    margin-left: 10px;
  }
  .el-button {
    position: absolute;
    right: 20px;
  }
}
.page-content {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 30px;
  .train-answer-content {
    width: 930px;
    border-radius: 4px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    padding: 20px 30px;
    box-shadow: 1px 4px 10px  rgba($color: #000000, $alpha: 0.15);
  }
 
}

</style>