<template>
  <div>
    <div class="special-title">
      <div>我的考卷</div>
    </div>
    <el-table :data="specialData" stripe style="width: 100%">
      <el-table-column prop="practiseName" label="考卷名称" width="180" />
      <el-table-column prop="typeName" label="类型" />
      <el-table-column prop="stateName" label="状态" />
      <el-table-column prop="startTs" label="开始时间"  width="180" />
      <el-table-column label="时长">
        <template #default="{ row }">
          {{format(row.duration)}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" >
        <template  #default="{row}">
          <el-button type="text" :disabled="row.state === 0 && row.practiseStatus === 0" @click="handleClick(row)">
            {{row.state === 0 ?
              row.practiseStatus === 0 ?
                '已下架' :
                '继续做题':
              '查看报告'}}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination 
        :page-size="query.size" 
        background 
        layout="prev, pager, next" 
        :total="total"
        @current-change="currentChange" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getMyPractiseList } from '@/api'
import { ref, reactive } from 'vue'
import { format } from '@/utils/tools'
import { useRouter } from 'vue-router'
const router = useRouter()

interface ISpecialItem {
  id: number
  practiseId: number
  practiseName: string
  typeName: string
  stateName: string
  startTs: string
  duration: number
  practiseStatus: number
  recordId: number
  state: number
}

const specialData = ref<ISpecialItem[] | []>([])
const query = reactive({
  page: 1,
  size: 10
})
const total = ref(0)

const getSpecialData = () => {
  getMyPractiseList(query).then((res:any)=>{
    if(res.code !== '200') return
    specialData.value = res.data || []
    total.value = res.total
  })
}
function goToPractice (currentId:number, recordId:number) {
  const routeData = router.resolve({
    name: 'train-answer',
    query: {recordId: recordId},
    params: {id: currentId}
  })
  window.open(routeData.href, '_blank')
}
const handleClick = (item: ISpecialItem) => {
  if (item.state === 0) {
    goToPractice(item.practiseId, item.id)
  } else {
    const routeData = router.resolve({
      name: 'report',
      query: {recordId: item.id},
      params: {practiseId: item.practiseId}
    })
    window.open(routeData.href, '_blank')
  }
}
const currentChange = (page:number) => {
  query.page = page
  getSpecialData()
}
getSpecialData()
</script>

<style lang="scss" scoped>
  .special-title {
    font-size: 30px;
    font-weight: bolder;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 23px;
    margin-bottom: 20px;
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
</style>